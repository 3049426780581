import React from 'react'
import Layout from '../components/layout'
import trtData from '../content/trt-data.json'
import GetInTouch from '../components/get-in-touch'
import { Link } from 'gatsby'
import Metatags from '../components/Metatags';

const ServicesPage = () => {
    function ScrollToWebDevelopment() {
        var srollDiv = document.getElementById("webDevelopment");
        srollDiv.scrollIntoView({ behavior: 'smooth' });
    }
    function ScrollToMobileDevelopment() {
        var srollDiv = document.getElementById("mobileDevelopment");
        srollDiv.scrollIntoView({ behavior: 'smooth' });
    }
    function ScrollToVoiceDevelopment() {
        var srollDiv = document.getElementById("voiceDevelopment");
        srollDiv.scrollIntoView({ behavior: 'smooth' });
    }
    return (
        <Layout>
            <div className="trt-services">
                <div className="services-banner">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4 order-1 order-lg-0">
                                <h1 className="d-none d-sm-block">Diensten</h1>
                                <p>Wij bieden diverse ontwikkelingsdiensten</p>
                                <label onClick={ScrollToWebDevelopment}>AutoCAD 2d</label>
                                <label onClick={ScrollToMobileDevelopment}>Inventor</label>
                                <label onClick={ScrollToVoiceDevelopment}>Solidworks</label>
                            </div>
                            <div className="col-lg-8 text-center">
                                <h1 className="d-block d-sm-none">Onze diensten</h1>
                                <img src="../images/services-banner-new.png"
                                    alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gradient">
                    <div className="container">
                        <div className="development" id="webDevelopment">
                            <div className="row align-items-center">
                                <div className="col-lg-4">
                                    <img className="w-100 mb-4 mb-lg-0"
                                        src="../images/audocad-icon.png"
                                        alt="" />
                                </div>
                                <div className="col-lg-8">
                                    <h3>AutoCAD 2d</h3>
                                    <p>Ons team is zeker het 2d werk niet verleerd. In sommige gevallen kan een simpelgrondplan, schets of idee snel en eenvoudig uitgewerkt worden in AutoCAD. Voor sommige klanten volstaat zelfs een AutoCAD tekening voor in de werkplaats.</p>
                                </div>
                            </div>
                        </div>

                        <div className="development" id="mobileDevelopment">
                            <div className="row align-items-center">
                                <div className="col-lg-8 order-1 order-lg-0">
                                    <h3>Inventor</h3>
                                    <p>Een groot gedeelte van ons werk wordt gedaan met Autodesk Inventor. Eenmaal een bibliotheek opgebouwd van onderdelen is de opbouw van een ander product een fluitje van een cent. Exporteren van stukslijsten op assemblage niveau of op artikel niveau naar een ERP programma is geen werk meer. Producttekeningen voor werkplaats of om uit te besteden. De klant bepaalt de output.</p>
                                </div>
                                <div className="col-lg-4">
                                    <img className="w-100 mb-4 mb-lg-0"
                                        src="../images/inInventor-icon.png"
                                        alt="" />
                                </div>
                            </div>
                        </div>

                        <div className="development" id="voiceDevelopment">
                            <div className="row align-items-center">
                                <div className="col-lg-4">
                                    <img className="w-100 mb-4 mb-lg-0"
                                        src="../images/solidwork-icon.png"
                                        alt="" />
                                </div>
                                <div className="col-lg-8">
                                    <h3>Solidworks</h3>
                                    <p>Een ander programma waar wij mee kunnen werken is Solidworks. In principe werken wij hiermee hetzelfde als met Inventor.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <GetInTouch />
            </div>
        </Layout >
    )
}

export default ServicesPage